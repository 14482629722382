const TopServiceCard = ({ image, body, heading, icon }) => {
  return (
    <div>
      <div className="flex justify-center lg:justify-start">
        <img
          src={image}
          alt=""
          className={`bg-[#BCE1FC32] dark:bg-[#BCE1FC16] p-2 rounded border border-[#BCE1FC] dark:border-[#BCE1FC32] ${icon}`}
        />
      </div>
      <p className="font-semibold text-[#1DA1F2] mt-4 mb-2 text-lg crimson-font">
        {heading}
      </p>
      <p className="text-xs leading-relaxed text-center lg:text-justify">
        {body}
      </p>
    </div>
  );
};

export default TopServiceCard;
