import { useFormik } from "formik";
import Input from "../../../components/input";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserProject,
  listUserProjects,
  updateUserProject,
} from "../../../hooks/local/userReducer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Modal from "../../../components/modal";

const ManageProject = () => {
  document.title = "Manage projects - CloudVantage";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteProjectModal, setDeleteProjectModal] = useState(false);

  const selectedProject = useSelector((state) => state.user.selectedProject);
  useEffect(() => {
    if (selectedProject) {
      updateProject.setValues({
        projectId: selectedProject.id,
        projectDescription: selectedProject.description,
        projectName: selectedProject.name,
      });
    }
  }, [selectedProject]);

  const deleteProject = async () => {
    const projectId = selectedProject.id;
    const { payload } = await dispatch(deleteUserProject(projectId));
    if (payload.statuscode === "00") {
      await dispatch(listUserProjects());
      setDeleteProjectModal(false);
      navigate("/client-area");
    }
  };

  const updateProject = useFormik({
    initialValues: {
      projectId: selectedProject.id,
      projectDescription: selectedProject.description,
      projectName: selectedProject.name,
    },
    validationSchema: Yup.object({
      projectName: Yup.string().required("Project Name cannot be empty"),
      projectDescription: Yup.string().required(
        "Project Description cannot be empty",
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { projectId, projectName, projectDescription } = values;
      let updateProjectData = {
        name: projectName,
        description: projectDescription,
        projectId,
      };
      const { payload } = await dispatch(
        updateUserProject({ updateProjectData, navigate }),
      );
      if (payload.statuscode === "00") {
        await dispatch(listUserProjects());
        navigate("/client-area");
      }
    },
  });

  return (
    <div className="dark:text-white mb-10">
      <p>Project details:</p>
      <form onSubmit={updateProject.handleSubmit}>
        <div className="w-full lg:w-[70%] mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="md:col-span-2">
            <Input
              label={"Project name"}
              type={"text"}
              name={"projectName"}
              onBlur={updateProject.handleBlur}
              onChange={updateProject.handleChange}
              value={updateProject.values.projectName}
              onError={
                updateProject.touched.name && updateProject.errors.projectName
                  ? updateProject.errors.projectName
                  : null
              }
            />
          </div>
          <div className="md:col-span-2">
            <Input
              label={"Project description"}
              placeholder={"Update your project description"}
              type={"text"}
              name={"projectDescription"}
              onBlur={updateProject.handleBlur}
              onChange={updateProject.handleChange}
              value={updateProject.values.projectDescription}
              onError={
                updateProject.touched.projectDescription &&
                updateProject.errors.projectDescription
                  ? updateProject.errors.projectDescription
                  : null
              }
            />
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="py-4 px-10 rounded-md bg-primaryBlue text-white text-sm"
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <hr className="my-10 dark:border-gray-600" />
      <div className="grid md:flex justify-between gap-6">
        <div>
          <p>Delete project</p>
          <p className="mt-2 text-sm text-gray-500">
            Deleting this project means you will loose all resources within it.
            Do you want to continue?
          </p>
        </div>
        <button
          className="p-4 rounded-md bg-red-500 text-white text-sm"
          onClick={() => setDeleteProjectModal(true)}
        >
          Delete project
        </button>
      </div>

      <Modal
        title={"Delete Project"}
        isVisible={deleteProjectModal}
        onClose={() => setDeleteProjectModal(false)}
      >
        Are you sure you want to delete {selectedProject.name}?
        <div className="flex justify-between pt-6">
          <button
            className="bg-red-500 text-white text-sm px-6 py-2 rounded-sm"
            onClick={deleteProject}
          >
            Yes
          </button>
          <button
            className="bg-[#1DA1F2] text-white text-sm px-6 py-2 rounded-sm"
            onClick={() => setDeleteProjectModal(false)}
          >
            No
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ManageProject;
