import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CartContext } from "../../../components/cartContext";
import ThemeToggler from "../../../components/themeToggler";
import { ThemeContext } from "../../../components/themeContext";

// Import images and icons
import LogoIcon from "../../../assets/images/cloudVantage Logo.png";
import icon from "../../../assets/images/cloudVantage Icon.png";
import WhiteLogoIcon from "../../../assets/images/cloudVantage Logo White.png";
import WhiteIcon from "../../../assets/images/cloudVantage Icon White.png";
import cart from "../../../assets/svg-icons/cart.svg";
import menu from "../../../assets/svg-icons/menu.svg";
import menuWhite from "../../../assets/svg-icons/menuWhite.svg";
import menuBrand from "../../../assets/svg-icons/menuBrand.svg";
import projectsFolder from "../../../assets/svg-icons/folder.svg";
import close from "../../../assets/svg-icons/closeBrand.svg";
import arrow from "../../../assets/svg-icons/arrow.svg";
import logout from "../../../assets/svg-icons/logoutIcon.svg";
import hamburger from "../../../assets/svg-icons/hamburger-menu.svg";
import add from "../../../assets/svg-icons/addWhite.svg";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import TextArea from "../../../components/textArea";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createProject,
  listServers,
  listUserProjects,
  setSelectedProject,
  switchUserProject,
} from "../../../hooks/local/userReducer";
import Spinner from "../../../components/spinner";
import { useServerList } from "./resuableEffect";
// import addWhite from "../../../assets/svg-icons/addWhite.svg";

const TopBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const { cartNumber } = useContext(CartContext);
  const [serviceVisible, setServiceVisible] = useState(false);
  const [projectsVisible, setProjectsVisible] = useState(false);
  const [smallScreenMenu, setSmallScreenMenu] = useState(false);
  const [addNewProjectDiv, setAddNewProjectDiv] = useState(false);
  const userProjectList = useSelector((state) => state.user.userProjectList);
  const selectedProject = useSelector((state) => state.user.selectedProject);

  useEffect(() => {
    if (userProjectList && userProjectList.length > 0 && !selectedProject) {
      dispatch(setSelectedProject(userProjectList[0]));
    }
  }, [dispatch, userProjectList, selectedProject]);

  const handleProjectSelection = async (userSelectedProject) => {
    await dispatch(setSelectedProject(userSelectedProject));
    const { payload } = await dispatch(
      switchUserProject(userSelectedProject.id),
    );

    if (payload.statuscode === "00") {
      const currentPath = location.pathname;
      if (currentPath.includes("manageSecurityGroups")) {
        window.location.href = "/securityGroups";
      } else if (
        currentPath === "/client-area" ||
        currentPath === "/securityGroups" ||
        currentPath === "/configure-vps"
      ) {
        window.location.reload();
      }
    }
    closeProjects();
  };

  const toggleServices = () => {
    setServiceVisible(true);
    setSmallScreenMenu(false);
  };

  const closeServices = () => {
    setServiceVisible(false);
  };

  const toggleProjects = () => {
    setProjectsVisible(true);
    setSmallScreenMenu(false);
  };

  const closeProjects = () => {
    setProjectsVisible(false);
  };

  const toggleSmallScreenMenu = () => {
    setSmallScreenMenu(true);
  };

  const closeSmallScreenMenu = () => {
    setSmallScreenMenu(false);
  };

  // Use theme context instead of local state
  const createNewProject = useFormik({
    initialValues: {
      projectDescription: "",
      projectName: "",
    },
    validationSchema: Yup.object({
      projectName: Yup.string().required("Project Name cannot be empty"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { projectName, projectDescription } = values;
      let createProjectData = {
        name: projectName,
        description: projectDescription,
      };
      const { payload } = await dispatch(
        createProject({ createProjectData, navigate }),
      );
      if (payload.statuscode === "00") {
        await dispatch(listUserProjects());
        resetForm();
        setAddNewProjectDiv(false);
        setProjectsVisible(true);
        navigate("/client-area");
      }
    },
  });

  return (
    <div className="relative">
      <Spinner loading={useSelector((state) => state.user).loading} />
      <div className="bg-white py-4 px-4 md:pe-10 dark:bg-[#141D26] border-b dark:border-b-[#c4c4c432] dark:text-white">
        <div className="flex items-center justify-between">
          <div>
            <Link to={"/client-area"}>
              <img
                src={theme === "dark" ? WhiteLogoIcon : LogoIcon}
                alt=""
                className="hidden lg:block h-[18px]"
              />
            </Link>
            <Link to={"/client-area"}>
              <img
                src={theme === "dark" ? WhiteIcon : icon}
                alt=""
                className="lg:hidden h-6"
              />
            </Link>
          </div>
          <div className="flex gap-3 items-center">
            <div
              onClick={toggleProjects}
              className="hidden md:flex text-sm items-center px-3 py-2 cursor-pointer"
            >
              <span className="">
                {selectedProject ? selectedProject.name : ""}
              </span>
              <img
                src={theme === "dark" ? menuWhite : menu}
                alt=""
                className="h-[18px]"
              />
            </div>
            <div
              onClick={toggleServices}
              className="hidden md:flex text-sm items-center px-3 py-2 cursor-pointer"
            >
              <span className="">Services</span>
              <img
                src={theme === "dark" ? menuWhite : menu}
                alt=""
                className="h-[18px]"
              />
            </div>
            <Link
              to={"/faq"}
              className="hidden md:block text-sm px-3 py-2 cursor-pointer"
            >
              <span className="">FAQs</span>
            </Link>
            <div className="md:hidden" onClick={toggleSmallScreenMenu}>
              <img src={hamburger} alt="" className="h-5" />
            </div>
            <Link
              to={"/client-area/configure-vps"}
              className="flex gap-[10px] items-center bg-[#1DA1F2] rounded p-2 md:p-[14px]"
            >
              <img src={cart} alt="" className="h-4" />
              <span className="text-xs text-white">{cartNumber}</span>
            </Link>
            <ThemeToggler />
          </div>
        </div>
      </div>

      {serviceVisible && (
        <div className="w-full fixed h-screen bg-black/70 top-0 px-4 flex items-end md:items-center justify-center">
          <div className="bg-white dark:bg-[#141D26] rounded w-full md:w-1/2 lg:w-2/5 dark:text-white max-h-[80%] mb-4 md:mb-0 overflow-auto">
            <div className="flex justify-between items-center text-sm p-6 border-b dark:border-b-[#c4c4c432]">
              <div className="flex gap-2 items-center">
                <img src={menuBrand} alt="" className="h-[18px]" />
                <span>Services</span>
              </div>
              <img
                src={close}
                onClick={closeServices}
                className="h-5 cursor-pointer"
                alt=""
              />
            </div>
            <div className="grid text-xs px-4 pb-4 pt-2">
              <Link
                to={"/client-area/vps"}
                onClick={closeServices}
                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
              >
                <span>Create dews</span>
                <span>
                  <img src={arrow} alt="" className="-rotate-90" />
                </span>
              </Link>
              <Link
                to={"/client-area/volumes"}
                onClick={closeServices}
                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
              >
                <span>Manage volumes</span>
                <span>
                  <img src={arrow} alt="" className="-rotate-90" />
                </span>
              </Link>
              <a
                href="https://vantagecoops.com/"
                onClick={closeServices}
                target="blank"
                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
              >
                <span>Vantage Coops</span>
                <span>
                  <img src={arrow} alt="" className="-rotate-90" />
                </span>
              </a>
              <Link
                to={"/loadBalancer"}
                onClick={closeServices}
                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
              >
                <span>Load balancers</span>
                <span>
                  <img src={arrow} alt="" className="-rotate-90" />
                </span>
              </Link>
              {/* <div className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all">
                <span>Email services</span>
                <span className="text-primaryBlue">
                  Coming soon...
                </span>
              </div> */}
            </div>
          </div>
        </div>
      )}

      {projectsVisible && (
        <div className="w-full fixed h-screen bg-black/70 top-0 px-4 flex items-end md:items-center justify-center">
          <div className="bg-white dark:bg-[#141D26] rounded w-full md:w-[30%] dark:text-white max-h-[80%] mb-4 md:mb-0 overflow-auto">
            <div className="flex justify-between items-center text-sm p-6 border-b dark:border-b-[#c4c4c432]">
              <div className="flex gap-2 items-center">
                <img src={projectsFolder} alt="" className="h-[18px]" />
                <span className="dark:text-white">My projects</span>
              </div>
              <img
                src={close}
                onClick={closeProjects}
                className="h-5 cursor-pointer"
                alt=""
              />
            </div>
            <div className="grid text-xs px-4 pb-4 pt-2">
              {userProjectList &&
                userProjectList.length > 0 &&
                userProjectList.map((val, key) => {
                  return (
                    <div
                      key={key}
                      onClick={() => {
                        handleProjectSelection(val);
                      }}
                      className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all cursor-pointer"
                    >
                      <span>{val.name}</span>
                      <span>
                        <img src={arrow} alt="" className="-rotate-90" />
                      </span>
                    </div>
                  );
                })}

              <div
                onClick={() => {
                  setAddNewProjectDiv(true);
                  setProjectsVisible(false);
                }}
                className="flex items-center justify-between bg-primaryBlue py-4 px-3 mt-4 rounded-md cursor-pointer"
              >
                <span>Create a new project</span>
                <img src={add} alt="" className="h-5" />
              </div>
            </div>
          </div>
        </div>
      )}

      {smallScreenMenu && (
        <div className="w-full fixed h-screen bg-black/70 top-0 px-4 flex items-end justify-center">
          <div className="bg-white dark:bg-[#141D26] rounded w-full dark:text-white max-h-[80%] mb-4 md:mb-0 overflow-auto">
            <div className="flex justify-between items-center text-sm p-6 border-b dark:border-b-[#c4c4c432]">
              <div className="flex gap-2 items-center">
                <img src={menuBrand} alt="" className="h-[18px]" />
                <span className="dark:text-white">Menu</span>
              </div>
              <img
                src={close}
                onClick={closeSmallScreenMenu}
                className="h-5 cursor-pointer"
                alt=""
              />
            </div>
            <div className="grid text-xs px-4 pb-4 pt-2">
              <Link
                to={"/client-area"}
                onClick={closeSmallScreenMenu}
                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
              >
                <span>Resources</span>
                <span>
                  <img src={arrow} alt="" className="-rotate-90" />
                </span>
              </Link>
              <Link
                to={"/securityGroups"}
                onClick={closeSmallScreenMenu}
                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
              >
                <span>Security groups</span>
                <span>
                  <img src={arrow} alt="" className="-rotate-90" />
                </span>
              </Link>
              <Link
                to={"/billing"}
                onClick={closeSmallScreenMenu}
                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
              >
                <span>Billing</span>
                <span>
                  <img src={arrow} alt="" className="-rotate-90" />
                </span>
              </Link>
              <Link
                to={"/help"}
                onClick={closeSmallScreenMenu}
                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
              >
                <span>Support</span>
                <span>
                  <img src={arrow} alt="" className="-rotate-90" />
                </span>
              </Link>
              <Link
                to={"/help"}
                onClick={() => {
                  sessionStorage.setItem("supportType", "payment-dispute");
                  closeSmallScreenMenu();
                }}
                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
              >
                <span>Payment dispute</span>
                <span>
                  <img src={arrow} alt="" className="-rotate-90" />
                </span>
              </Link>
              <Link
                to={"/settings"}
                onClick={closeSmallScreenMenu}
                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
              >
                <span>Settings</span>
                <span>
                  <img src={arrow} alt="" className="-rotate-90" />
                </span>
              </Link>
              <span
                onClick={() => {
                  setSmallScreenMenu(false);
                  toggleProjects();
                }}
                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
              >
                <span>My projects</span>
                <span>
                  <img src={arrow} alt="" className="-rotate-90" />
                </span>
              </span>
              <span
                onClick={() => {
                  setSmallScreenMenu(false);
                  toggleServices();
                }}
                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
              >
                <span>Services</span>
                <span>
                  <img src={arrow} alt="" className="-rotate-90" />
                </span>
              </span>
              <Link
                to={"/faq"}
                onClick={closeSmallScreenMenu}
                className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all"
              >
                <span>FAQs</span>
                <span>
                  <img src={arrow} alt="" className="-rotate-90" />
                </span>
              </Link>
              <Link to={"/sign-out"}>
                <div className="flex items-center justify-between hover:rounded border-b dark:border-[#c4c4c424] last:border-none py-4 px-3 hover:bg-primaryBlue/5 transition-all">
                  <span>Logout</span>
                  <span>
                    <img src={logout} alt="" />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}

      <Modal
        isVisible={addNewProjectDiv}
        title={"Create New Project"}
        onClose={() => {
          setAddNewProjectDiv(false);
          setProjectsVisible(true);
        }}
      >
        <form onSubmit={createNewProject.handleSubmit}>
          <div className="grid gap-4 mt-8">
            <Input
              label={"Project name"}
              type={"text"}
              name={"projectName"}
              value={createNewProject.values.projectName}
              onBlur={createNewProject.handleBlur}
              onChange={createNewProject.handleChange}
              onError={
                createNewProject.errors.projectName &&
                createNewProject.touched.projectName
                  ? createNewProject.errors.projectName
                  : null
              }
            />
            <TextArea
              label={"Project description"}
              type={"text"}
              noOfRow={"3"}
              name={"projectDescription"}
              value={createNewProject.values.projectDescription}
              onBlur={createNewProject.handleBlur}
              onChange={createNewProject.handleChange}
              onError={
                createNewProject.errors.projectDescription &&
                createNewProject.touched.projectDescription
                  ? createNewProject.errors.projectDescription
                  : null
              }
            />
            <div className="grid">
              <button
                type="submit"
                className="text-sm md:text-xs opacity-70 text-white bg-[#1DA1F2] rounded px-8 py-4 cursor-pointer"
              >
                Create Project
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default TopBar;
