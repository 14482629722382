import { useState } from "react";
import eye from "../assets/svg-icons/eye.svg";
import { countryCodes } from "./countryCodes";

const Input = ({
  label,
  type,
  isPassword,
  placeholder,
  name,
  value,
  onChange,
  onError,
  onBlur,
  disabled,
  countryCodeDisplay,
}) => {
  const [inputType, setInputType] = useState(type);

  const togglePassword = () => {
    setInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  return (
    <div>
      <div className="grid gap-1 text-sm dark:text-white">
        <span className="text-sm">{label}</span>
        <div className="relative w-full">
          {isPassword === "true" ? (
            <div
              className="absolute top-0 h-full right-0 p-1 cursor-pointer"
              onClick={togglePassword}
            >
              <span className="aspect-square bg-lightPrimary rounded h-full flex items-center justify-center">
                <img src={eye} alt="" />
              </span>
            </div>
          ) : null}
          <div className="flex gap-1">
            <select
              name="currency"
              className={`py-4 rounded-md border border-[#c4c4c460] focus:outline-0 bg-white dark:bg-slate-800 text-xs ${
                countryCodeDisplay === "true" ? "block" : "hidden"
              }`}
            >
              {countryCodes.map((country, index) => (
                <option key={index} value={country.code}>
                  {country.code} ({country.name})
                </option>
              ))}
            </select>
            <input
              type={inputType}
              placeholder={placeholder}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              className="w-full p-4 rounded-md border border-[#c4c4c460] focus:outline-0 placeholder:text-xs dark:bg-slate-800"
            />
          </div>
        </div>
      </div>
      <span className="text-red-500 text-xs">{onError}</span>
    </div>
  );
};

export default Input;
