import deleteIcon from "../../../assets/svg-icons/deleteRed.svg";
import close from "../../../assets/svg-icons/closeBrand.svg";
import attachIcon from "../../../assets/svg-icons/attach.svg";
import back from "../../../assets/svg-icons/back.svg";
import Input from "../../../components/input";
import Select from "../../../components/select";
import { useState, useEffect } from "react";
import {
  getSingleSecurityGroup,
  listServers,
  createSecurityGroupRule,
  deleteSecurityGroupRule,
  createSecurityGroupInstance,
} from "../../../hooks/local/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";

const ManageSecurityGroups = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const selectedProjectId = useSelector(
    (state) => state.user.selectedProject.id,
  );

  const [groupDetails, setGroupDetails] = useState({
    id: "",
    name: "",
    description: "",
  });
  const [rules, setRules] = useState([]);
  const [instanceOptions, setInstanceOptions] = useState([]);

  const fetchServers = async () => {
    try {
      const { payload } = await dispatch(listServers(selectedProjectId));

      const options = payload.map((server) => ({
        value: server.id,
        label: server.name,
      }));
      setInstanceOptions(options);
    } catch (error) {
      console.error("Error fetching servers:", error);
    }
  };

  useEffect(() => {
    if (selectedProjectId) {
      fetchServers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId]);

  const [instance, selectedInstance] = useState("");

  const handleInstanceChange = (event) => {
    const serverInstance = event.target.value;
    selectedInstance(serverInstance);
  };

  const fetchSecurityGroupRuleData = async () => {
    const { payload } = await dispatch(
      getSingleSecurityGroup({
        projectId: selectedProjectId,
        securityGroupId: id,
      }),
    );

    const data = payload?.data?.security_group;

    if (data) {
      setGroupDetails({
        id: data?.id,
        name: data?.name,
        description: data?.description,
      });
    }

    setRules(
      data?.security_group_rules?.map((rule) => ({
        id: rule.id,
        description: rule.description || "No description",
        direction: rule.direction,
        ethertype: rule.ethertype,
        ipProtocol: rule.protocol || "Null",
        portRange: rule.port_range_min
          ? `${rule.port_range_min}-${rule.port_range_max}`
          : "Null",
      })) || [],
    );
  };

  useEffect(() => {
    fetchSecurityGroupRuleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedProjectId]);

  const data = rules;

  useEffect(() => {
    if (id) fetchSecurityGroupRuleData();
  }, [id]);

  const [addRuleModal, setAddRuleModal] = useState(false);

  const toggleAddRuleModal = () => {
    setAddRuleModal(!addRuleModal);
    if (addRuleModal) {
      setSelectedType("");
    }
  };

  const [instanceModal, setInstanceModal] = useState(false);

  const toggleInstanceModal = () => {
    setInstanceModal(!instanceModal);
  };

  const securityGroupType = [
    { name: "SSH", code: "22" },
    { name: "HTTP", code: "80" },
    { name: "HTTPS", code: "443" },
    { name: "MYSQL", code: "3306" },
    { name: "All TCP", code: "tcp" },
    { name: "All UDP", code: "udp" },
    { name: "ICMP", code: "icmp" },
    { name: "Custom TCP", code: "custom" },
  ];

  const [selectedType, setSelectedType] = useState("");

  const handleTypeChange = (event) => {
    const selectedCode = event.target.value;
    setSelectedType(selectedCode);

    switch (selectedCode) {
      case "22":
        createNewSecurityGroupRule.setValues((prev) => ({
          ...prev,
          port_range_min: "22",
          port_range_max: "22",
        }));
        break;
      case "80":
        createNewSecurityGroupRule.setValues((prev) => ({
          ...prev,
          port_range_min: "80",
          port_range_max: "80",
        }));
        break;
      case "443":
        createNewSecurityGroupRule.setValues((prev) => ({
          ...prev,
          port_range_min: "443",
          port_range_max: "443",
        }));
        break;
      case "3306":
        createNewSecurityGroupRule.setValues((prev) => ({
          ...prev,
          port_range_min: "3306",
          port_range_max: "3306",
        }));
        break;
      case "tcp":
        createNewSecurityGroupRule.setValues((prev) => ({
          ...prev,
          port_range_min: "",
          port_range_max: "",
        }));
        break;
      case "udp":
        createNewSecurityGroupRule.setValues((prev) => ({
          ...prev,
          port_range_min: "",
          port_range_max: "",
        }));
        break;
      case "icmp":
        createNewSecurityGroupRule.setValues((prev) => ({
          ...prev,
          port_range_min: "",
          port_range_max: "",
        }));
        break;
      case "custom":
        createNewSecurityGroupRule.setValues((prev) => ({
          ...prev,
          port_range_min: "",
          port_range_max: "",
        }));
        break;
      default:
        createNewSecurityGroupRule.setValues((prev) => ({
          ...prev,
          port_range_min: "",
          port_range_max: "",
        }));
        break;
    }
  };

  const createAttachSecurityGroupInstance = useFormik({
    initialValues: {},
    onSubmit: async (values, { resetForm }) => {
      let instanceData = {
        ...values,
        server_id: instance,
        security_group_id: id,
      };

      const { payload } = await dispatch(
        createSecurityGroupInstance({
          instanceData,
          projectId: selectedProjectId,
        }),
      );

      if (payload) {
        resetForm();
        selectedInstance("");
        toggleInstanceModal();
      }
    },
  });

  const createNewSecurityGroupRule = useFormik({
    initialValues: {
      ethertype: "IPv4",
      port_range_max: "",
      port_range_min: "",
      description: "",
    },
    validationSchema: Yup.object({
      ethertype: Yup.string().required("Ether type cannot be empty"),
      port_range_max: Yup.string(),
      port_range_min: Yup.string(),
      description: Yup.string(),
    }),

    onSubmit: async (values, { resetForm }) => {
      const protocol = ["22", "80", "443", "3306", "custom"].includes(
        selectedType,
      )
        ? "tcp"
        : selectedType === "tcp"
          ? "tcp"
          : selectedType === "udp"
            ? "udp"
            : selectedType === "icmp"
              ? "icmp"
              : "";

      let dataToSubmit = { ...values, direction: "ingress", protocol };

      if (["tcp", "udp", "icmp"].includes(selectedType)) {
        delete dataToSubmit.port_range_min;
        delete dataToSubmit.port_range_max;
      }

      if (dataToSubmit.description === "") {
        delete dataToSubmit.description;
      }

      const { payload } = await dispatch(
        createSecurityGroupRule({
          securityGroupRuleData: dataToSubmit,
          projectId: selectedProjectId,
          securityGroupId: groupDetails?.id,
        }),
      );

      if (payload) {
        toggleAddRuleModal();
        fetchSecurityGroupRuleData();
        resetForm();
        setSelectedType("");
      }
    },
  });

  const handleDeleteSecurityGroupRule = async (securityGroupRuleId) => {
    const { payload } = await dispatch(
      deleteSecurityGroupRule({
        projectId: selectedProjectId,
        securityGroupRuleId,
      }),
    );
    if (payload) fetchSecurityGroupRuleData();
  };

  return (
    <div className="px-4 md:px-10 py-6 min-h-screen relative">
      <div className="mb-6 grid gap-1">
        <div className="flex gap-3 items-center">
          <div
            className="p-2 cursor-pointer bg-primaryBlue/10 rounded-md"
            onClick={() => window.history.go(-1)}
          >
            <img alt="" src={back} className="h-4" />
          </div>
          <p className="crimson-font text-xl font-medium text-primaryBlue">
            Manage{" "}
            <span className="underline font-semibold">{groupDetails.name}</span>
          </p>
        </div>
        <p className="text-sm text-black/50 dark:text-white/50 truncate gap-1 flex">
          <span className="">Group ID:</span>
          <span className="truncate font-semibold">{groupDetails.id}</span>
        </p>
        <p className="text-sm text-black/50 dark:text-white/50 truncate gap-1 flex">
          <span className="">Description:</span>
          <span className="truncate font-semibold">
            {groupDetails.description}
          </span>
        </p>
      </div>

      <div className="mb-6 md:flex md:justify-between md:items-center grid gap-4">
        <div className="">
          <div
            onClick={toggleInstanceModal}
            className="px-4 py-3 text-sm md:text-xs border-[1.5px] border-primaryBlue rounded-md text-primaryBlue cursor-pointer flex items-center gap-1"
          >
            <img src={attachIcon} alt="Delete" className="h-4" />
            <span>Attach to instance</span>
          </div>
        </div>
        <div className="flex items-center gap-4 text-sm md:text-xs">
          <div>
            <div
              onClick={toggleAddRuleModal}
              className="px-4 py-3 border-[1.5px] border-primaryBlue rounded-md text-primaryBlue cursor-pointer"
            >
              Add new rule
            </div>
          </div>
          {/* <div>
            <div className="px-4 py-3 bg-red-500 border-[1.5px] border-red-500 text-white rounded-md">
              Delete Rules
            </div>
          </div> */}
        </div>
      </div>

      <div className="overflow-x-auto rounded-md border dark:border-[#c4c4c432] dark:text-white">
        <table className="w-full text-sm text-left overflow-hidden">
          <thead className="bg-white dark:bg-slate-800 px-3 md:px-4 border dark:border-[#c4c4c432] text-sm font-semibold">
            <tr>
              <th scope="col" className="whitespace-nowrap py-4 px-6"></th>
              <th scope="col" className="whitespace-nowrap py-4 px-6">
                Direction
              </th>
              <th scope="col" className="whitespace-nowrap py-4 px-6">
                Ether Type
              </th>
              <th scope="col" className="whitespace-nowrap py-4 px-6">
                IP Protocol
              </th>
              <th scope="col" className="whitespace-nowrap py-4 px-6">
                Port Range
              </th>
              <th scope="col" className="whitespace-nowrap py-4 px-6">
                Description
              </th>
              <th scope="col" className="whitespace-nowrap py-4 px-6">
                {" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-slate-800 text-black/50 dark:text-white/50 px-3 md:px-4 border rounded-b-md dark:border-[#c4c4c432] text-sm">
            {data.map((row, index) => (
              <tr
                key={index}
                className="dark:hover:bg-[#ffffff05] hover:bg-brandBlue/25 whitespace-nowrap"
              >
                <td className="text-start py-4 px-6">{index + 1}</td>
                <td className="text-start py-4 px-6">{row.direction}</td>
                <td className="text-start py-4 px-6">{row.ethertype}</td>
                <td className="text-start py-4 px-6">{row.ipProtocol}</td>
                <td className="text-start py-4 px-6">{row.portRange}</td>
                <td className="text-start py-4 px-6  max-w-20 truncate">
                  {row.description}
                </td>
                <td className="text-start py-4 px-6">
                  <div
                    className="bg-red-300/10 hover:bg-red-500/10 rounded-md cursor-pointer transition-all p-2 w-fit"
                    onClick={() => handleDeleteSecurityGroupRule(row.id)}
                  >
                    <img src={deleteIcon} alt="" className="h-4" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* add new rule modal */}
      {addRuleModal && (
        <div className="fixed top-0 h-screen w-full left-0 z-50 bg-black/60 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-[#141D26] rounded-md p-4 md:p-10 w-full md:w-1/3 max-h-[80%] overflow-y-scroll">
            <div className="flex items-center justify-between mb-4">
              <p className="text-primaryBlue">Add a new rule</p>
              <span onClick={toggleAddRuleModal} className="cursor-pointer">
                <img alt="" src={close} className="h-5" />
              </span>
            </div>
            <div>
              <div className="mt-6 mb-4 text-sm">Security group rules:</div>
              <div className="mb-4 grid grid-cols-1 gap-4 items-end">
                <div className="grid gap-1">
                  <span className="text-sm">Rule</span>

                  <select
                    className="py-4 rounded-md border border-[#c4c4c460] focus:outline-0 bg-white dark:bg-slate-800 text-sm"
                    value={selectedType}
                    onChange={handleTypeChange}
                  >
                    <option value="" disabled>
                      Select Rule
                    </option>
                    {securityGroupType.map((securityType, index) => (
                      <option key={index} value={securityType.code}>
                        {securityType.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="grid gap-1">
                  <span className="text-sm">EtherType</span>

                  <Select
                    name={"ethertype"}
                    className="py-4 rounded-md border border-[#c4c4c460] focus:outline-0 bg-white dark:bg-slate-800 text-sm"
                    options={[
                      {
                        value: "IPv4",
                        label: "IPv4",
                      },
                    ]}
                    value={{
                      value:
                        createNewSecurityGroupRule.values.ethertype || "IPv4",
                      label: "IPv4",
                    }}
                    onChange={(option) => {
                      createNewSecurityGroupRule.setFieldValue(
                        "ethertype",
                        option.value,
                      );
                    }}
                    onError={
                      createNewSecurityGroupRule.errors.ethertype &&
                      createNewSecurityGroupRule.touched.ethertype
                        ? createNewSecurityGroupRule.errors.ethertype
                        : null
                    }
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <Input
                    label={"Port range (min)"}
                    name={"port_range_min"}
                    value={createNewSecurityGroupRule.values.port_range_min}
                    onChange={createNewSecurityGroupRule.handleChange}
                    onBlur={createNewSecurityGroupRule.handleBlur}
                    onError={
                      createNewSecurityGroupRule.touched.port_range_min &&
                      createNewSecurityGroupRule.errors.port_range_min
                        ? createNewSecurityGroupRule.errors.port_range_min
                        : null
                    }
                    disabled={selectedType !== "custom"}
                  />
                  <Input
                    label={"Port range (max)"}
                    name={"port_range_max"}
                    value={createNewSecurityGroupRule.values.port_range_max}
                    onChange={createNewSecurityGroupRule.handleChange}
                    onBlur={createNewSecurityGroupRule.handleBlur}
                    onError={
                      createNewSecurityGroupRule.touched.port_range_max &&
                      createNewSecurityGroupRule.errors.port_range_max
                        ? createNewSecurityGroupRule.errors.port_range_max
                        : null
                    }
                    disabled={selectedType !== "custom"}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    label={"Description"}
                    placeholder={"Start typing..."}
                    name={"description"}
                    value={createNewSecurityGroupRule.values.description}
                    onChange={createNewSecurityGroupRule.handleChange}
                    onBlur={createNewSecurityGroupRule.handleBlur}
                    onError={
                      createNewSecurityGroupRule.touched.description &&
                      createNewSecurityGroupRule.errors.description
                        ? createNewSecurityGroupRule.errors.description
                        : null
                    }
                  />
                </div>
              </div>

              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  createNewSecurityGroupRule.handleSubmit(e);
                }}
                className="text-sm md:text-xs text-white bg-[#1DA1F2] rounded px-8 py-4 cursor-pointer"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {/* instance modal */}
      {instanceModal && (
        <div className="fixed top-0 h-screen w-full left-0 z-50 bg-black/60 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-[#141D26] rounded-md p-4 md:p-10 w-full md:w-1/3 max-h-[80%] overflow-y-scroll">
            <div className="flex items-center justify-between mb-4">
              <p className="text-primaryBlue">
                Attach instance to security group
              </p>
              <span onClick={toggleInstanceModal} className="cursor-pointer">
                <img alt="" src={close} className="h-5" />
              </span>
            </div>

            <div>
              <select
                className="py-4 rounded-md border border-[#c4c4c460] focus:outline-0 bg-white dark:bg-slate-800 text-sm w-full"
                value={instance}
                onChange={handleInstanceChange}
              >
                <option value="" disabled>
                  Select Instance
                </option>
                {instanceOptions.map((instance, index) => (
                  <option key={index} value={instance.value}>
                    {instance.label}
                  </option>
                ))}
              </select>

              <div className="mt-6">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    createAttachSecurityGroupInstance.handleSubmit(e);
                  }}
                  className="bg-[#1DA1F2] text-white text-sm px-6 py-4 rounded-sm"
                >
                  Attach to instance
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageSecurityGroups;
