import { useState } from "react";
import Select from "../../../components/select";
import arrow from "../../../assets/svg-icons/blueArrow.svg";
import { Link } from "react-router-dom";

const Billing = () => {
  document.title = "Billing - Cloudvantage";

  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setCurrentPage(1);
  };

  const options = [
    { value: "all-services", label: "All services" },
    { value: "vps", label: "Virtual Private Servers" },
    { value: "vantage-coops", label: "Vantage Coops", },
  ];

  const data = [
    {
      invoiceNumber: "211923",
      invoiceDate: "Thurs - March 17, 2024",
      dueDate: "Fri - March 18, 2024",
      amount: "₦5000",
      status: "Make payment",
    },
    {
      invoiceNumber: "211924",
      invoiceDate: "Fri - March 18, 2024",
      dueDate: "Sat - March 19, 2024",
      amount: "₦7000",
      status: "Paid",
    },
    {
      invoiceNumber: "211925",
      invoiceDate: "Sat - March 19, 2024",
      dueDate: "Sun - March 20, 2024",
      amount: "₦6000",
      status: "Make payment",
    },
    {
      invoiceNumber: "211926",
      invoiceDate: "Sun - March 20, 2024",
      dueDate: "Mon - March 21, 2024",
      amount: "₦8000",
      status: "Paid",
    },
    {
      invoiceNumber: "211927",
      invoiceDate: "Mon - March 21, 2024",
      dueDate: "Tue - March 22, 2024",
      amount: "₦5500",
      status: "Make payment",
    },
    {
      invoiceNumber: "211928",
      invoiceDate: "Tue - March 22, 2024",
      dueDate: "Wed - March 23, 2024",
      amount: "₦7200",
      status: "Paid",
    },
    {
      invoiceNumber: "211929",
      invoiceDate: "Wed - March 23, 2024",
      dueDate: "Thu - March 24, 2024",
      amount: "₦4800",
      status: "Make payment",
    },
    {
      invoiceNumber: "211930",
      invoiceDate: "Thu - March 24, 2024",
      dueDate: "Fri - March 25, 2024",
      amount: "₦6700",
      status: "Paid",
    },
    {
      invoiceNumber: "211931",
      invoiceDate: "Fri - March 25, 2024",
      dueDate: "Sat - March 26, 2024",
      amount: "₦5200",
      status: "Make payment",
    },
    {
      invoiceNumber: "211932",
      invoiceDate: "Sat - March 26, 2024",
      dueDate: "Sun - March 27, 2024",
      amount: "₦6900",
      status: "Paid",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 5;

  const filteredData = data.filter((row) =>
    row.invoiceNumber.toLowerCase().includes(searchText.toLowerCase()),
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry,
  );
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="min-h-screen px-4 md:px-10 pt-6 pb-10 dark:text-white">
      <p className="crimson-font text-xl font-medium text-primaryBlue">
        Manage your billing
      </p>
      
      {/* <div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 items-end my-10">
          <div className="md:col-span-3">
            <Select label="Filter services" name="services" options={options} />
          </div>
          <div className="md:col-span-4">
            <input
              type="search"
              name=""
              value={searchText}
              onChange={handleSearchChange}
              placeholder="Search invoice number"
              className="bg-white text-black w-full p-4 text-sm rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
            />
          </div>
        </div>
        <div className="overflow-x-auto rounded-md border dark:border-[#c4c4c432]">
          <table className="w-full text-sm text-left overflow-hidden">
            <thead className="bg-white dark:bg-slate-800 px-3 md:px-4 border text-gray-400 dark:border-[#c4c4c432] border-b text-sm font-semibold">
              <tr>
                <th scope="col" className="px-6 py-4 whitespace-nowrap">
                  Invoice no.
                </th>
                <th scope="col" className="px-6 py-4 whitespace-nowrap">
                  Invoice date
                </th>
                <th scope="col" className="px-6 py-4 whitespace-nowrap">
                  Due date
                </th>
                <th scope="col" className="px-6 py-4 whitespace-nowrap">
                  Amount
                </th>
                <th scope="col" className="px-6 py-4 whitespace-nowrap">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-slate-800 px-3 md:px-4 border rounded-b-md dark:border-[#c4c4c432] border-t-2 text-sm">
              {currentEntries.map((row, index) => (
                <tr
                  key={index}
                  className="dark:hover:bg-[#ffffff05] hover:bg-brandBlue/25 whitespace-nowrap"
                >
                  <td className="text-start py-4 px-6">{row.invoiceNumber}</td>
                  <td className="text-start py-4 px-6">{row.invoiceDate}</td>
                  <td className="text-start py-4 px-6">{row.dueDate}</td>
                  <td className="text-start py-4 px-6">{row.amount}</td>
                  <td className="text-start py-4 px-6">{row.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="grid justify-center gap-4 md:flex md:justify-between items-center mt-4">
          <p className="text-gray-400 text-sm">
            Showing{" "}
            <span className="font-medium text-black dark:text-white">
              {indexOfFirstEntry + 1}
            </span>{" "}
            to{" "}
            <span className="font-medium text-black dark:text-white">
              {Math.min(indexOfLastEntry, data.length)}
            </span>{" "}
            of{" "}
            <span className="font-medium text-black dark:text-white">
              {data.length}
            </span>{" "}
            results
          </p>
          <div className=" flex gap-3">
            <button
              className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${currentPage === 1 ? "opacity-50" : "opacity-100"}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className={`bg-white dark:bg-primaryBlue border dark:border-none rounded-md py-3 px-5 text-sm ${currentPage === totalPages ? "opacity-50" : "opacity-100"}`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div> */}

      <div className="md:px-52 text-center grid text-gray-500 mt-32">
        <div>It looks like you don't have a billing history yet. Once you make a payment for a service, your billing details will appear here.</div>
        <Link to={"/client-area/vps"} className="border border-[#c4c4c450] dark:border-[#c4c4c416] rounded-md p-3 flex items-center justify-self-center mt-8 w-fit text-xs">
          <span>View VPS plans</span>
          <span className="ps-2">
            <img src={arrow} alt="" />
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Billing;
