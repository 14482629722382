import { Outlet } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

const Layout = () => {
    return ( 
        <div className="grid content-between min-h-screen">
          <div className="relative">
            <div className="w-full absolute top-0"> <Navbar /> </div>
            <div className="md:px-10 lg:px-[100px] mt-16 md:mt-20">
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
  );
};

export default Layout;
