import pattern from "../../assets/images/bluePattern.svg";
import whatsapp from "../../assets/svg-icons/whatsApp.svg";

const Contact = () => {
  document.title = "Contact - Cloudvantage";
  return (
    <div className="px-4 md:px-0 grid gap-4 py-6">
      <div className="bg-brandBlue rounded-md grid grid-cols-1 lg:grid-cols-5">
        <div className="lg:col-span-3 px-6 md:px-10 py-10 md:py-16">
          <p className="text-lg leading-snug crimson-font font-bold mb-4">
            Contact us.
          </p>
          <p className="text-sm">
            We understand that sometimes you may encounter difficulties or have
            questions while using our platform. We're here to assist you every
            step of the way. Feel free to reach out to us.
          </p>
        </div>
        <div className="lg:col-span-2 flex items-end md:h-32 lg:h-full overflow-clip">
          <img src={pattern} alt="" className="w-full" />
        </div>
      </div>
      <div className="bg-white rounded-md grid grid-cols-1 md:grid-cols-2 gap-10 p-6 md:p-10">
        <div className="md:border-r">
          <p className="text-lg leading-snug crimson-font font-bold mb-4">
            Call us.
          </p>
          <div className="grid gap-3 text-sm">
            <span>
              You can reach our support team on{" "}
              <span className="text-[#1DA1F2] underline underline-offset-2">
                +2347080704824
              </span>
            </span>
            <span>Monday - Friday</span>
            <span>8:00 am - 8:00pm</span>
          </div>
        </div>
        <div>
          <p className="text-lg leading-snug crimson-font font-bold mb-1">
            Send a message.
          </p>
          <p className="text-sm">
            For quick help on your account and product issues
          </p>
          <button className="bg-black text-white text-xs px-6 py-4 rounded-sm mt-6">
            <div className="flex gap-2">
              <img src={whatsapp} alt="" className="h-4" />
              <span>Start chat</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
