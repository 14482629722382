import { useContext, useEffect, useState } from "react";
import remove from "../../../assets/svg-icons/remove.svg";
import { CartContext } from "../../../components/cartContext";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/spinner";

const MyCart = () => {
  const [cartItems, setCartItems] = useState([]);
  const { setCartNumber } = useContext(CartContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const cart = sessionStorage.getItem("cart");
    if (cart) {
      setCartItems((prevCartItems) => JSON.parse(cart));
    }
  }, []);

  const calculateTotalPrice = (cartItems) => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      const price = parseFloat(item.price.replace("NGN", "").replace(",", ""));
      totalPrice += price;
    });
    return totalPrice;
  };

  const removeFromCart = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1);
    setCartItems(updatedCartItems);
    sessionStorage.setItem("cart", JSON.stringify(updatedCartItems));
    setCartNumber(updatedCartItems.length);
  };

  let totalPrice = calculateTotalPrice(cartItems).toLocaleString();
  const useremail = useSelector((state) => state.user.userSessionData).email;
  const transid = Array.from(
    { length: 8 },
    () => Math.floor(Math.random() * (10 - 1 + 1)) + 1,
  ).join("");
  let orders = cartItems.map((item, index) => ({
    name: item.name,
    id: item.productId,
    amount: item.price.replace("NGN", ""),
  }));

  const placeServerOrder = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: async (values) => {
      const total_amount = totalPrice;

      let orderRequest = { transid, useremail, total_amount, orders };

      const { payload } = await dispatch(orderRequest);

      if (payload.statuscode === "00") {
        sessionStorage.removeItem("cart");
        // window.location.href = "/client-area";
        navigate("/client-area");
      }
    },
  });

  return (
    <div className="px-4 md:px-10 pt-6 pb-16 min-h-screen">
      <Spinner loading={useSelector((state) => state.user).loading} />
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-8 ">
          <div className="border-b dark:border-[#c4c4c432] flex justify-between items-center pb-3">
            <span className="text-xl font-semibold dark:text-white crimson-font">
              Cart
            </span>
            <span className="text-[#1DA1F2] text-xs">
              {cartItems.length} items
            </span>
          </div>
          <div className="mt-4 grid gap-4">
            {cartItems.length > 0 ? (
              cartItems.map((val, key) => {
                return (
                  <div className=" bg-[#c4c4c416] p-4 rounded border-b text-sm dark:border-b-[#c4c4c432] dark:text-white">
                    <div className="grid">
                      <div className="flex justify-between items-center">
                        <span className="opacity-60 text-xs">
                          Product name:
                        </span>
                        <span
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => removeFromCart(key)}
                        >
                          <img src={remove} alt="" className="h-5" />
                        </span>
                      </div>
                      <div className="flex justify-between items-end">
                        <div className="grid gap-1">
                          <div>{val.name}</div>
                          <div className="text-xs grid md:flex gap-1 md:gap-16 mt-2">
                            <span>
                              VCPUs: <span className="ps-1">{val.vcpu}</span>
                            </span>
                            <span>
                              RAM: <span className="ps-1">{val.ramSize}</span>
                            </span>
                            <span>
                              Root disk:{" "}
                              <span className="ps-1">{val.diskSize}</span>
                            </span>
                          </div>
                        </div>
                        <div className="font-semibold">{val.price}</div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex justify-center">
                <div className="text-center">
                  <p className="text-[#1da1f2] text-xl font-medium crimson-font">
                    Your cart is empty!
                  </p>
                  <p className="dark:text-white/50 text-black/50 text-xs">
                    Return to the homepage to select and buy a Flavour
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-12 lg:col-span-4">
          <div className="bg-[#1da1f2] rounded text-white">
            <div className="border-b border-white/30 p-4">
              <span className="font-semibold crimson-font">Order summary</span>
            </div>
            <div className="p-4 text-sm grid gap-4">
              <div className="flex justify-between">
                <p>Items:</p>
                <p>{cartItems.length}</p>
              </div>
              {/* List out additional features that cost money here */}
              <div className="flex justify-between">
                <p>Total:</p>
                <p className="font-semibold">NGN {totalPrice}</p>
              </div>
              <div className="mt-4">
                {cartItems.length > 0 ? (
                  <div>
                    <button
                      className="text-[#1da1f2] bg-white rounded p-4 text-xs w-full"
                      type="submit"
                      onClick={placeServerOrder.handleSubmit}
                    >
                      Place order!
                    </button>
                    <p className="text-center mt-4 text-sm hover:underline cursor-pointer">
                      Remove item from cart
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCart;
