
import pattern from "../assets/images/pricingPattern.svg";

const PricingCard = ({category, price, duration, buttonColor, buttonText, actionType,destination, onClick}) => {
    return ( 
       <div> 
        <div className="bg-white rounded-md">
          <div className="h-32 p-1 relative">
            <img src={pattern} alt="" className="h-full w-full object-cover rounded-md" />
            <div className="absolute bottom-0 left-0 text-[13px] p-3">
              <div className="bg-black/30 rounded-md backdrop-blur-md text-xs p-2 text-white">{category}</div>
            </div>
          </div>
          <div className="p-4 grid gap-6">
            <div>
              <span className="text-xs">Starts at:</span>
              <p className="font-bold text-lg crimson-font text-[#1da1fc]">NGN {price} <span className="text-sm font-semibold text-black/50">/ {duration}</span></p>
            </div>
            {actionType === "link" ?
            <a href={destination} target="blank" style={{ backgroundColor: buttonColor }} className="text-white text-xs px-6 py-4 rounded-sm text-center">{buttonText}</a> :
            <div onClick={onClick} style={{ backgroundColor: buttonColor }} className="text-white text-xs px-6 py-4 rounded-sm text-center cursor-pointer">{buttonText}</div>
            }
            </div>
        </div>
    </div>
  );
};
export default PricingCard;
