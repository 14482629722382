// import { useEffect, useState } from "react";
import cpu from "../assets/svg-icons/cpu-icon.svg";
import ram from "../assets/svg-icons/ram.svg";
import disk from "../assets/svg-icons/disk.svg";
import whiteDisk from "../assets/svg-icons/whiteDisk.svg";
import whiteRAM from "../assets/svg-icons/whiteRAM.svg";
import whiteCPU from "../assets/svg-icons/whiteCPU.svg";
import deleteIcon from "../assets/svg-icons/delete.svg";
import { Link } from "react-router-dom";

const Flavours = ({
  name,
  price,
  vcpu,
  ramSize,
  diskSize,
  addToCart,
  removeFromCart,
  productId,
  isInCart,
  nairaPrice,
  dollarPrice,
  flavorId,
}) => {
  const handleAddToCart = () => {
    addToCart({
      name,
      price,
      vcpu,
      ramSize,
      diskSize,
      productId,
      nairaPrice,
      dollarPrice,
      flavorId,
    });
  };

  const handleRemoveFromCart = () => {
    removeFromCart(productId, flavorId);
  };

  return (
    <div className="border border-[#c4c4c450] dark:border-[#c4c4c416] dark:text-white rounded p-4 hover:bg-white dark:hover:bg-[#ffffff08] cursor-default">
      <p className="font-medium text-sm text-black/50 dark:text-white/50">
        {name}
      </p>
      <p className="crimson-font text-[#1da1fc] truncate my-2">
        <span className="font-semibold text-xl">{price}</span>
        <span className="text-sm font-normal text-black/50 dark:text-white/50">
          /month
        </span>
      </p>
      <div className="text-xs grid gap-3">
        <div className="flex gap-2 items-center">
          <img src={cpu} alt="" className="h-5 md:h-[18px] dark:hidden" />
          <img src={whiteCPU} alt="" className="h-5 md:h-[18px] hidden dark:block" />
          <span>VCPUs: {vcpu}</span>
        </div>
        <div className="flex gap-2 items-center">
          <img src={ram} alt="" className="h-5 md:h-[18px] dark:hidden" />
          <img src={whiteRAM} alt="" className="h-5 md:h-[18px] hidden dark:block" />
          <span>RAM: {ramSize}</span>
        </div>
        <div className="flex gap-2 items-center">
          <img src={disk} alt="" className="h-5 md:h-[18px] dark:hidden" />
          <img src={whiteDisk} alt="" className="h-5 md:h-[18px] hidden dark:block" />
          <span>Storage: {diskSize} (NVMe)</span>
        </div>
      </div>
      <div className="mt-5 text-sm">
        {isInCart ? (
          <div className="flex items-center gap-1">
            <Link to={"/client-area/configure-vps"} className="bg-primaryBlue p-4 rounded text-xs w-[75%] text-white text-center">
              Configure
            </Link>
            <div
              className="bg-red-500 w-[25%] flex items-center justify-center py-4 text-white rounded text-xs cursor-pointer"
              onClick={handleRemoveFromCart}
            >
              <img src={deleteIcon} alt="" className="h-4" />
            </div>
          </div>
        ) : (
          <div
            className="bg-[#1DA1F2] w-full p-4 text-white rounded text-center text-xs cursor-pointer"
            onClick={handleAddToCart}
          >
            <span>Select plan</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Flavours;
