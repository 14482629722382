const TextArea = ({
  label,
  type,
  placeholder,
  name,
  value,
  onChange,
  onError,
  onBlur,
  noOfRow,
}) => {
  return (
    <div>
      <div>
        <div className="grid text-sm dark:text-white gap-1">
          <span className="text-sm">{label}</span>
          <div className="relative w-full">
            <div>
              <textarea
                type={type}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                rows={noOfRow}
                className="w-full p-4 rounded-md border border-[#c4c4c460] focus:outline-0 placeholder:text-xs dark:bg-slate-800"
              >
                {value}
              </textarea>
            </div>
          </div>
        </div>
        <span className="text-red-500 text-xs">{onError}</span>
      </div>
    </div>
  );
};

export default TextArea;
