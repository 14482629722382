import Support from "./support";

const Help = () => {
  return (
    <div className="md:px-10 min-h-screen">
      <Support />
    </div>
  );
};

export default Help;
