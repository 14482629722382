import { useState } from "react";
import { Link } from "react-router-dom";
import Domain from "../../../components/FAQs/domainFAQ";
import VPS from "../../../components/FAQs/vpsFAQ";
import Email from "../../../components/FAQs/EmailFAQ";
import VPN from "../../../components/FAQs/vpnFAQ";

const FAQs = () => {
  const [selectedOption, setSelectedOption] = useState("vps");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const idle =
    "py-4 px-6 text-xs cursor-pointer border border-[#00000030] dark:border-[#ffffff30] rounded-md text-[#00000080] dark:text-[#ffffff80]";
  const active =
    "m-0 py-4 px-6 text-xs cursor-pointer bg-[#1DA1F2] rounded-md text-white";

  return (
    <div className="min-h-screen px-4 md:px-10 pt-6 pb-10">
      <p className="crimson-font text-xl font-medium text-primaryBlue">
        Frequently Asked Questions.
      </p>
      <div className="mt-6">
        <div className="grid grid-cols-2 text-center md:flex gap-2 mb-6">
          <span
            className={`${selectedOption === "vps" ? "active" + active : idle}`}
            onClick={() => handleOptionClick("vps")}
          >
            VPS
          </span>
          <span
            className={`${
              selectedOption === "domain" ? "active" + active : idle
            }`}
            onClick={() => handleOptionClick("domain")}
          >
            Domains
          </span>
          <span
            className={`${
              selectedOption === "email" ? "active" + active : idle
            }`}
            onClick={() => handleOptionClick("email")}
          >
            Email services
          </span>
          <span
            className={`${selectedOption === "vpn" ? "active" + active : idle}`}
            onClick={() => handleOptionClick("vpn")}
          >
            VPN
          </span>
        </div>
        <div style={{ display: selectedOption === "vps" ? "block" : "none" }}>
          {" "}
          <VPS />{" "}
        </div>
        <div
          style={{
            display: selectedOption === "domain" ? "block" : "none",
          }}
        >
          {" "}
          <Domain />{" "}
        </div>
        <div style={{ display: selectedOption === "email" ? "block" : "none" }}>
          {" "}
          <Email />{" "}
        </div>
        <div style={{ display: selectedOption === "vpn" ? "block" : "none" }}>
          {" "}
          <VPN />{" "}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
