import React, { useState, useContext, useRef, useEffect } from "react";
import Logoicon from "../assets/images/cloudVantage Logo.png";
import WhiteLogoicon from "../assets/images/cloudVantage Logo White.png";
import MenuIcon from "../assets/svg-icons/menuicon";
import { Link, useLocation } from "react-router-dom";
import user from "../assets/svg-icons/user.svg";
import ThemeToggler from "./themeToggler";
import { ThemeContext } from "./themeContext";
import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  const location = useLocation();
  const [menuVisible, setMenuVisible] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  // Use theme context instead of local state
  const { theme } = useContext(ThemeContext);

  return (
    <div className="dark:text-white fixed md:sticky top-0 z-50 w-full">
      <div className="flex md:hidden items-center gap-4 bg-white py-6 md:py-4 dark:bg-[#141D26] px-4 md:px-10 lg:px-[100px]">
        <MenuIcon
          action={toggleMenu}
          colorTheme={theme === "dark" ? "#ffffff" : "#141D26"}
        />
        <Link to={"/"}>
          <img
            src={theme === "dark" ? WhiteLogoicon : Logoicon}
            alt=""
            className="lg:hidden h-[18px]"
          />
        </Link>
      </div>
      <div
        className={`grid lg:flex gap-8 lg:gap-10 items-center justify-between bg-white border-b dark:border-[#c4c4c432] dark:bg-[#141D26] px-4 md:px-10 lg:px-[100px] py-6 md:py-4 ${
          menuVisible ? "block" : "hidden"
        }`}
      >
        <div className="hidden lg:block">
          <Link to={"/"}>
            <img
              src={theme === "dark" ? WhiteLogoicon : Logoicon}
              alt=""
              className="h-[18px]"
            />
          </Link>
        </div>
        <div className="grid lg:flex gap-8 lg:gap-10 items-center">
          <div className="grid lg:flex lg:space-x-10 space-y-6 lg:space-y-0 text-[13px]">
            <div className="cursor-pointer">
              <span onClick={toggleDropdown} ref={dropdownRef}>
                Products
              </span>
              {showDropdown && (
                <div className="absolute mt-1 md:mt-2 min-w-44 max-w-56 bg-white dark:bg-[#141D26] shadow-lg border dark:border-[#c4c4c432] rounded-lg z-10 font-medium">
                  <ul className="p-2 truncate">
                    <li className="p-3 rounded hover:bg-primaryBlue/5 hover:text-primaryBlue transition-all cursor-pointer flex items-center gap-2 truncate">
                      <span className="truncate">Cloud Hosting</span>
                    </li>
                    <li className="p-3 rounded hover:bg-primaryBlue/5 hover:text-primaryBlue transition-all cursor-pointer flex items-center gap-2 truncate">
                      <span className="truncate">Domain Hosting</span>
                    </li>
                    <li className="p-3 rounded hover:bg-primaryBlue/5 hover:text-primaryBlue transition-all cursor-pointer flex items-center gap-2 truncate">
                      <span className="truncate">Vantage Coops</span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <Link
              to={"/contact"}
              onClick={closeMenu}
              className={`${
                location.pathname === "/contact"
                  ? "text-primaryBlue font-semibold"
                  : ""
              }`}
            >
              <span>Contact us</span>
            </Link>
            <Link
              to={"/support"}
              onClick={closeMenu}
              className={`${
                location.pathname === "/support"
                  ? "text-primaryBlue font-semibold"
                  : ""
              }`}
            >
              Support
            </Link>
            <Link
              to={"/pricing"}
              onClick={closeMenu}
              className={`${
                location.pathname === "/pricing"
                  ? "text-primaryBlue font-semibold"
                  : ""
              }`}
            >
              <span>Pricing</span>
            </Link>
            <HashLink smooth to="/#faq" onClick={closeMenu}>
              <span className={`${
                location.pathname === "/#faq"
                  ? "text-primaryBlue font-semibold"
                  : ""
              }`}>FAQs</span></HashLink>
            <Link
              to={"/about-us"}
              onClick={closeMenu}
              className={`${
                location.pathname === "/about-us"
                  ? "text-primaryBlue font-semibold"
                  : ""
              }`}
            >
              <span>About us</span>
            </Link>
          </div>
          <div className="grid lg:flex gap-6">
            <Link
              to={"/sign-in"}
              onClick={closeMenu}
              className="flex gap-2 items-center bg-white dark:bg-transparent md:dark:bg-[#C9E9FC] lg:bg-[#C9E9FC] py-0 lg:py-3 px-0 lg:px-4 rounded"
            >
              <img src={user} alt="" className="hidden lg:block" />
              <span>
                <span className="text-sm lg:text-xs lg:text-[#1DA1F2] font-normal lg:font-medium">
                  My account
                </span>
              </span>
            </Link>
            <ThemeToggler />
          </div>
        </div>
      </div>
      {/* <div className={`h-screen md:h-fit bg-white border-b dark:border-[#c4c4c432] dark:bg-[#141D26] grid lg:flex justify-between items-center px-4 md:px-10 lg:px-[100px] py-6 md:py-4 ${menuVisible ? "block" : "hidden"}`}>
              <div className="hidden lg:block">
                <Link to={"/"}>
                  <img
                    src={theme === "dark" ? WhiteLogoicon : Logoicon}
                    alt=""
                    className="h-[18px]"
                  />
                </Link>
              </div>
              <div className="grid lg:flex gap-8 lg:gap-10 items-center">
                <div className="grid lg:flex lg:space-x-10 space-y-6 lg:space-y-0 text-[13px]">
                  <Link
                    to={"/contact"}
                    onClick={closeMenu}
                    className={`${
                      location.pathname === "/contact"
                        ? "text-primaryBlue font-semibold"
                        : ""
                    }`}
                  >
                    <span>Contact us</span>
                  </Link>
                  <Link
                    to={"/support"}
                    onClick={closeMenu}
                    className={`${
                      location.pathname === "/support"
                        ? "text-primaryBlue font-semibold"
                        : ""
                    }`}
                  >
                    Support
                  </Link>
                  <Link
                    to={"/pricing"}
                    onClick={closeMenu}
                    className={`${
                      location.pathname === "/pricing"
                        ? "text-primaryBlue font-semibold"
                        : ""
                    }`}
                  >
                    <span>Pricing</span>
                  </Link>
                  <a
                    href="/#faq"
                    onClick={closeMenu}
                    className={`${
                      location.pathname === "/pricing"
                        ? "text-primaryBlue font-semibold"
                        : ""
                    }`}
                  >
                    <span>FAQs</span>
                  </a>
                  <Link
                    to={"/about-us"}
                    onClick={closeMenu}
                    className={`${
                      location.pathname === "/about-us"
                        ? "text-primaryBlue font-semibold"
                        : ""
                    }`}
                  >
                    <span>About us</span>
                  </Link>
                </div>
                <div className="grid lg:flex gap-6">
                  <Link
                    to={"/sign-in"}
                    onClick={closeMenu}
                    className="flex gap-2 items-center bg-white dark:bg-transparent md:dark:bg-[#C9E9FC] lg:bg-[#C9E9FC] py-0 lg:py-3 px-0 lg:px-4 rounded"
                  >
                    <img src={user} alt="" className="hidden lg:block" />
                    <span>
                      <span className="text-sm lg:text-xs lg:text-[#1DA1F2] font-normal lg:font-medium">
                        My account
                      </span>
                    </span>
                  </Link>
                  <ThemeToggler />
                </div>
              </div>
        </div> */}
    </div>
  );
};

export default Navbar;
