import { useFormik } from "formik";
import Select from "../../../components/select";
import { useServerList } from "../layout/resuableEffect";
import * as Yup from "yup";
import Input from "../../../components/input";
import { useDispatch, useSelector } from "react-redux";
import { createServerSnapshot } from "../../../hooks/local/userReducer";
import { useNavigate } from "react-router-dom";

const Snapshot = () => {
  document.title = "Take a snapshot - Cloudvantage";
  const userServerDew = useServerList();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedProjected = useSelector((state) => state.user.selectedProject);
  const selectedServer = JSON.parse(sessionStorage.getItem("selectedServer"));
  // const serverOptions = userServerDew.map(server => ({
  //   value: server.id,
  //   label: server.name.replace(".", " "),
  // }))
  // const options = [
  //   { value: "Select-dew", label: "Select a dew" },
  //   ...serverOptions
  // ];

  const createSnapshot = useFormik({
    initialValues: {
      snapshotName: "",
    },
    validationSchema: Yup.object({
      snapshotName: Yup.string().required("Snapshot name cannot be empty"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { snapshotName } = values;
      let snapshotData = {
        volume_id: selectedServer.serverId,
        name: snapshotName,
        projectId: selectedProjected.id,
      };
      const { payload } = await dispatch(
        createServerSnapshot({ snapshotData, navigate }),
      );
      if (payload.statuscode === "00") {
        navigate("/client-area/snapshot");
      }
    },
  });

  return (
    <div>
      <p className="dark:text-white text-sm mb-1">
        Take a snapshot for {selectedServer.serverName} server(dew):
      </p>
      <p className="dark:text-gray-500 text-gray-400 text-sm mb-6 leading-loose md:leading-normal">
        A snapshot point-in-time backup of the entire virtual machine, including
        its data, system settings, and active processes. It allows for quick
        restoration in case of data loss or system issues, ensuring service
        continuity and security during updates or changes.
      </p>

      <div className="grid gap-4">
        <form onSubmit={createSnapshot.handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-4">
            {/* <Select
              name="dewId"
              options={options}
              value={options.values.dewId}
              onBlur={createSnapshot.handleBlur}
              onChange={(event) => createSnapshot.setFieldValue('dewId', event.target.value)}
              onError={createSnapshot.touched.dewId && createSnapshot.errors.dewId ? createSnapshot.errors.dewId : null}
            /> */}
            <div>
              <Input
                type={"text"}
                name={"snapshotName"}
                onBlur={createSnapshot.handleBlur}
                onChange={createSnapshot.handleChange}
                placeholder={"Give your snapshot a name"}
                value={createSnapshot.values.snapshotName}
                onError={
                  createSnapshot.errors.snapshotName &&
                  createSnapshot.touched.snapshotName
                    ? createSnapshot.errors.snapshotName
                    : null
                }
              />
            </div>
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="p-4 rounded-md bg-primaryBlue text-white text-sm"
            >
              Take snapshot
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Snapshot;
